import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import { initTable, updateTable } from '../../modules/redux/actions/mainTable';
import { HotTable } from 'react-handsontable/dist/ce/react-handsontable';
import { includes } from 'lodash';
import Handsontable from 'handsontable';
import 'antd/lib/spin/style/css';

import './tableMain.scss';

class TableMain extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  async componentDidMount() {
    await this.props.initTable();
    this.setState({
      loading: false,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pickedDate !== this.props.pickedDate) {
      this.props.initTable();
    }

    if (prevProps.activePoint !== this.props.activePoint) {
      this.props.initTable();
    }
  }

  cellRenderer(instance, td, row, col, prop, value, cellProperties) {
    Handsontable.renderers.NumericRenderer.apply(this, arguments);
  }

  render() {
    const { tableData, tableHeaders, rowHeaders, columns, totalRowsIndexes } = this.props;
    return (
      <div className="table-main">
        {this.state.loading ? (
          <div className="table-spinner_loading">
            <Spin />
          </div>
        ) : (
          <div className="table-main_container">
            <HotTable
              settings={{
                data: tableData,
                colHeaders: tableHeaders,
                rowHeaders: rowHeaders,
                fillHandle: false,
                rowHeaderWidth: 140,
                renderAllRows: true,
                columns: columns,
                stretchH: 'all',
                className: 'htCenter',
                cells: function(row, column, prop) {
                  const cellProperties = {};
                  cellProperties.type = 'numeric';
                  cellProperties.numericFormat = {
                    pattern: '$0,0.00',
                    culture: 'ru-RU',
                  };

                  if (prop === 'margin' || includes(totalRowsIndexes, row) || row === tableData.length - 1) {
                    cellProperties.readOnly = true;
                  }

                  return cellProperties;
                },
              }}
              maxRows={tableData.length}
              beforeChange={(changes, source) => {
                this.props.updateTable(changes);
              }}
              renderer={this.cellRenderer}
            />
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    pickedDate: state.common.pickedDate,
    activePoint: state.common.activePoint,
    tableData: state.mainTable.tableData,
    tableHeaders: state.mainTable.tableHeaders,
    rowHeaders: state.mainTable.rowHeaders,
    columns: state.mainTable.columns,
    totalRowsIndexes: state.mainTable.totalRowsIndexes,
  };
}

export default connect(
  mapStateToProps,
  {
    initTable,
    updateTable,
  }
)(TableMain);
