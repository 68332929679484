import React, { Component } from 'react';
import { Spin } from 'antd';
import 'antd/lib/spin/style/css';
import './componentPreloader.scss';

class ComponentPreloader extends Component {
  render() {
    return (
      <div className="page-component_preload">
        <Spin />
      </div>
    );
  }
}

export default ComponentPreloader;
