import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DatePicker, Badge, Modal, Tooltip, Button, message } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint, faDownload, faSignIn, faBars } from '@fortawesome/pro-regular-svg-icons';
import {
  handleDateChange,
  handleSidebarChange,
  saveTablesData,
  handleLoading,
  handleLoaded,
} from '../../modules/redux/actions/common';
import print from '../../util/printPdf';
import { userLogout } from '../../modules/redux/actions/auth';
import { withRouter } from 'react-router';
import moment from 'moment';
import 'antd/lib/date-picker/style/css';
import 'antd/lib/badge/style/css';
import 'antd/lib/modal/style/css';
import 'antd/lib/message/style/css';
import 'antd/lib/button/style/css';
import 'antd/lib/tooltip/style/css';
import 'antd/lib/message/style/css';

import './Header.scss';

const { MonthPicker } = DatePicker;
const confirm = Modal.confirm;

class Header extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      popupVisible: false,
      currentDate: moment(),
    };
  }

  handlePickerChange = async val => {
    if (this.props.dataChanged) {
      this.showConfirm(val);
    } else {
      this.setState({
        currentDate: moment(val),
      });
      await this.props.handleDateChange(val);
    }
  };

  handlePrintClick = () => {
    this.props.handleLoading();
    const { tableData, tableHeaders } = this.props;
    print(tableHeaders, tableData, (type, info) => {
      if (type === 'error') {
        message.error(info);
      }
      this.props.handleLoaded();
    });
  };

  showConfirm = val => {
    let $this = this;
    confirm({
      title: 'Данные будут утеряны',
      content: 'Если вы перейдете на другой месяц, несохранненые данные за этот месяц будут удалены',
      okText: 'Принять',
      cancelText: 'Отмена',
      onOk() {
        $this.props.handleDateChange(val);
      },
      onCancel() {
        console.log($this.props.pickedDate);
      },
    });
  };

  disabledDate = current => {
    return current > moment().endOf('month');
  };

  handleTablesSave = async () => {
    await this.props.saveTablesData();
    if (!this.props.dataSaveError) {
      message.success('Таблицы успешно сохранены');
    } else {
      message.error('Сохранение не удалось, попробуйте позже');
    }
  };

  handleLogout = async () => {
    localStorage.clear();
    await this.props.userLogout();
    this.props.history.replace('/login');
  };

  render() {
    const { pickedDate, activePoint, sidebarVisible } = this.props;
    return (
      <div className={`header d-print-none ${sidebarVisible ? 'sidebar-visible' : ''}`}>
        <div className="header-side_left">
          <div className="point-name">
            <h6>{activePoint ? activePoint.name : ''}</h6>
            {/*{superUser && <h6 className="header-desc_superuser">Статистика по франчайзи</h6>}*/}
          </div>
        </div>
        <div className="header-settings">
          <div className="month-picker_container">
            <MonthPicker
              allowClear={false}
              onChange={val => this.handlePickerChange(val)}
              size="small"
              disabledDate={this.disabledDate}
              value={moment(pickedDate, 'MMMM YYYY')}
              format="MMMM YYYY"
            />
          </div>
          <Tooltip placement="bottom" title="Распечатать">
            <Button onClick={this.handlePrintClick} className="header-icon header-icon_print" aria-label="Print">
              <FontAwesomeIcon icon={faPrint} />
            </Button>
          </Tooltip>
          <Tooltip placement="bottom" title="Сохранить">
            <Badge dot={this.props.dataChanged} className={`badge-save ${this.props.dataChanged ? 'pulse' : ''}`}>
              <Button className={`header-icon header-icon_save`} aria-label="Save" onClick={this.handleTablesSave}>
                <FontAwesomeIcon icon={faDownload} />
              </Button>
            </Badge>
          </Tooltip>
          <Tooltip placement="bottom" title="Выход">
            <Button className="header-icon header-icon_logout" aria-label="Logout" onClick={this.handleLogout}>
              <FontAwesomeIcon icon={faSignIn} />
            </Button>
          </Tooltip>
          <div className="sidebar-hide">
            <Button className="header-icon" aria-label="Menu" onClick={this.props.handleSidebarChange}>
              <FontAwesomeIcon icon={faBars} size="xs" />
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    activePoint: state.common.activePoint,
    pickedDate: state.common.pickedDate,
    dataChanged: state.common.dataChanged,
    sidebarVisible: state.common.sidebarVisible,
    dataSaveError: state.common.dataSaveError,
    columns: state.ordersTable.columns,
    tableData: state.ordersTable.tableData,
    tableHeaders: state.ordersTable.tableHeaders,
    rowHeaders: state.ordersTable.rowHeaders,
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    { handleDateChange, handleSidebarChange, userLogout, saveTablesData, handleLoading, handleLoaded }
  )(Header)
);
