import axios from 'axios';
import { findIndex } from 'lodash';
import moment from 'moment';

export function handleDateChange(date) {
  return dispatch => {
    dispatch({
      type: 'DATA_LOADING',
    });

    const chosenDate = moment(date).format();

    axios
      .get(`/api/v1/points/?date=${encodeURIComponent(chosenDate)}`)
      .then(async response => {
        await dispatch({
          type: 'GET_TABLES_DATA',
          payload: response.data,
          date: date,
        });

        dispatch({
          type: 'DATA_SAVED',
        });

        dispatch({
          type: 'DATA_LOADED',
        });
      })
      .catch(error => {
        dispatch({
          type: 'DATA_LOADING_ERROR',
        });
      });
  };
}

export function getData() {
  return async dispatch => {
    await axios
      .get(`/api/v1/points/`)
      .then(async response => {
        await dispatch({
          type: 'GET_TABLES_DATA',
          payload: response.data,
        });

        dispatch({
          type: 'DATA_LOADED',
        });
      })
      .catch(error => {
        console.log(error);
        dispatch({
          type: 'DATA_LOADING_ERROR',
        });
      });
  };
}

export function saveTablesData() {
  return async (dispatch, getState) => {
    const { mainTable, moneyTable, expenseTable, ordersTable, common } = getState();
    let pointsData = common.points.slice();
    let acitvePointIndex = findIndex(pointsData, common.activePoint);
    pointsData[acitvePointIndex] = {
      ...pointsData[acitvePointIndex],
      tables: {
        point_data: mainTable.tableData,
        movement_of_money: moneyTable.tableData,
        other_expenses: expenseTable.tableData,
        order:
          ordersTable.tableHeaders && ordersTable.tableHeaders.length
            ? {
                rows: ordersTable.tableData,
                headers: ordersTable.tableHeaders,
              }
            : [],
      },
    };

    const date = common.pickedDate.format();

    const body = {
      points: pointsData,
      date: date,
    };

    dispatch({
      type: 'DATA_LOADING',
    });

    await axios
      .post(`/api/v1/points/?date=${encodeURIComponent(date)}`, body)
      .then(response => {
        dispatch({
          type: 'SAVE_TABLES_DATA',
          payload: response.data,
        });

        dispatch({
          type: 'DATA_LOADED',
        });

        dispatch({
          type: 'DATA_SAVED',
        });
      })
      .catch(error => {
        dispatch({
          type: 'DATA_LOADED',
        });

        dispatch({
          type: 'DATA_SAVE_ERROR',
        });
      });
  };
}

export function changeActivePoint(key) {
  return (dispatch, getState) => {
    const { mainTable, moneyTable, expenseTable, ordersTable, common } = getState();

    let pointsData = common.points.slice();
    let acitvePointIndex = findIndex(pointsData, common.activePoint);
    pointsData[acitvePointIndex] = {
      ...pointsData[acitvePointIndex],
      tables: {
        point_data: mainTable.tableData,
        movement_of_money: moneyTable.tableData,
        other_expenses: expenseTable.tableData,
        order:
          ordersTable.tableHeaders && ordersTable.tableHeaders.length
            ? {
                rows: ordersTable.tableData,
                headers: ordersTable.tableHeaders,
              }
            : [],
      },
    };

    dispatch({
      type: 'CHANGE_ACTIVE_POINT',
      payload: key,
      points: pointsData,
    });
  };
}

export function handleLoading() {
  return dispatch =>
    dispatch({
      type: 'DATA_LOADING',
    });
}

export function handleLoaded() {
  return dispatch =>
    dispatch({
      type: 'DATA_LOADED',
    });
}

export function handleSidebarChange() {
  return dispatch => {
    dispatch({
      type: 'SIDEBAR_CHANGE',
    });
  };
}

export function handleYearChange(date) {
  return dispatch => {
    dispatch({
      type: 'HANDLE_YEAR_CHANGE',
      payload: date,
    });
  };
}

export function handleErrorBoundary(error) {
  const body = {
    data: error,
  };

  return () => {
    axios.post(`/api/v1/logging/`, body);
  };
}
