import React from 'react';
import { Modal, Button, Input, message } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { includes } from 'lodash';
import './modalOrderAdd.scss';

class ModalOrderAdd extends React.Component {
  state = {
    provider: '',
    visible: false,
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = e => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = e => {
    this.setState({
      provider: '',
      visible: false,
    });
  };

  handleProviderChange = e => {
    this.setState({
      provider: e.target.value,
    });
  };

  handleProviderSubmit = () => {
    const { provider } = this.state;
    const orders = this.props.tableHeaders.map(order => {
      return order.value;
    });
    if (provider) {
      if (!includes(orders, provider)) {
        this.props.addNewOrder(provider);
        this.setState({
          provider: '',
          visible: false,
        });
        message.success(`Поставщик с именем ${provider} успешно добавлен`);
      } else {
        message.error(`Поставщик с именем ${provider} уже существует`);
      }
    } else {
      message.error('Название поставщика не может быть пустым');
    }
  };

  render() {
    return (
      <div className="modal-order_add-container">
        <Button type="primary" onClick={this.showModal}>
          {this.props.float ? <FontAwesomeIcon icon={faPlus} /> : 'Добавить поставщика'}
        </Button>
        <Modal
          title="Добавить поставщика"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
          className="modal-order_add"
        >
          <span className="label">Название поставщика</span>
          <Input
            onChange={this.handleProviderChange}
            placeholder="Введите название поставщика"
            value={this.state.provider}
            size="large"
            autoFocus
          />
          <div className="buttons-container">
            <Button onClick={this.handleCancel} type="danger" ghost>
              <FontAwesomeIcon icon={faTimes} />
              Отмена
            </Button>
            <Button onClick={this.handleProviderSubmit} className="button-submit">
              <FontAwesomeIcon icon={faPlus} />
              Добавить
            </Button>
          </div>
        </Modal>
      </div>
    );
  }
}

export default ModalOrderAdd;
