export function initTable() {
  return (dispatch, getState) => {
    const { common } = getState();
    dispatch({
      type: 'INIT_EXPENSE_TABLE_DATA',
      payload: {
        pickedDate: common.pickedDate,
        expenseTable: common.expenseTable,
      },
    });
  };
}

export function updateTable(changes) {
  return dispatch => {
    dispatch({
      type: 'UPDATE_EXPENSE_TABLE_DATA',
      payload: changes,
    });

    dispatch({
      type: 'DATA_CHANGED',
    });
  };
}
