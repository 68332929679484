import { createMainTableData, updateTotalRowsMain } from '../../../util/tableHelpers';

const initialState = {
  tableData: [],
  rowHeaders: [],
  tableHeaders: [],
  columns: [],
  totalRowsIndexes: [],
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'INIT_TABLE_DATA':
      let tableHeaders = ['Эквайринг', 'Касса', 'Выручка', 'Расходы на товар', 'Маржа'];
      let initTableData;
      if (action.payload.mainTable && action.payload.mainTable.length) {
        initTableData = action.payload.mainTable.slice();
      } else {
        initTableData = createMainTableData(action.payload.pickedDate);
      }

      let totalRowsIndexes = [];
      initTableData.map((data, index) => {
        if (data.date === 'Итого за неделю') {
          totalRowsIndexes.push(index);
        }
      });

      const rowHeaders = initTableData.map(data => {
        return data.date;
      });

      let columns = [
        { data: 'acquiring' },
        { data: 'pointCashbox' },
        { data: 'earnings' },
        { data: 'costs' },
        { data: 'margin', readOnly: true },
      ];

      return {
        ...state,
        tableHeaders: tableHeaders,
        rowHeaders: rowHeaders,
        tableData: initTableData,
        columns: columns,
        totalRowsIndexes: totalRowsIndexes,
      };

    case 'UPDATE_TABLE_DATA':
      let row = action.payload[0][0];
      let column = action.payload[0][1];
      let newValue = action.payload[0][3];
      let tableData = state.tableData.slice();
      tableData[row][column] = +newValue;

      if (column === 'acquiring' || column === 'pointCashbox') {
        tableData[row]['earnings'] = +tableData[row]['acquiring'] + +tableData[row]['pointCashbox'];
      }

      if (column !== 'margin') {
        tableData[row]['margin'] = +tableData[row]['earnings'] - +tableData[row]['costs'];
      }

      tableData = updateTotalRowsMain(state.totalRowsIndexes, tableData, row);

      return {
        ...state,
        tableData: tableData,
      };

    default:
      return state;
  }
};
