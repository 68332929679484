import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const AdminRoute = ({ component: Component, ...rest }, props) => {
  const user = localStorage.getItem('user');
  const parsedUser = user ? JSON.parse(user) : null;
  const loggedIn = props.token
    ? props.token && props.user.is_superuser
    : localStorage.getItem('token') && parsedUser.is_superuser;
  return (
    <Route
      {...rest}
      render={props =>
        loggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
            }}
          />
        )
      }
    />
  );
};

function mapStateToProps(state) {
  return {
    token: state.auth.token,
    user: state.auth.user,
  };
}

export default connect(mapStateToProps)(AdminRoute);
