import moment from 'moment';
import { cyrToLat } from './cyrToLat';

function createDaysOfMonth(pickedDate) {
  const startOfMonth = moment(pickedDate).startOf('month');
  const endOfMonth = moment(pickedDate).endOf('month');

  let days = [];
  let day = startOfMonth;
  while (day <= endOfMonth) {
    days.push({ readOnly: true, value: moment(day).format('DD.MM.YYYY') });
    if (moment(day).isoWeekday() === 7 && moment(day).date() !== moment(endOfMonth).date()) {
      days.push({ readOnly: true, value: 'Итого за неделю' });
    } else if (moment(day).date() === moment(endOfMonth).date()) {
      days.push({ readOnly: true, value: 'Итого за неделю' });
      days.push({ readOnly: true, value: 'Итого за месяц' });
    }
    day = day.clone().add(1, 'd');
  }
  return days;
}

export function createMainTableData(pickedDate) {
  const daysOfMonth = createDaysOfMonth(pickedDate);
  let rowsArray = [];
  for (let i = 0; i < daysOfMonth.length; i++) {
    if (daysOfMonth[i].value !== 'Итого за неделю') {
      rowsArray.push({
        date: daysOfMonth[i].value,
        acquiring: '',
        pointCashbox: '',
        earnings: '',
        costs: '',
        margin: '',
      });
    } else if (daysOfMonth[i].value === 'Итого за месяц') {
      rowsArray.push({
        date: daysOfMonth[i].value,
        acquiring: '',
        pointCashbox: '',
        earnings: '',
        costs: '',
        margin: '',
      });
    } else if (daysOfMonth[i].value === 'Итого за неделю') {
      rowsArray.push({
        date: daysOfMonth[i].value,
        acquiring: '',
        pointCashbox: '',
        earnings: '',
        costs: '',
        margin: '',
      });
    }
  }

  return rowsArray;
}

export function createExpenseTableData(pickedDate) {
  const daysOfMonth = createDaysOfMonth(pickedDate);
  let rowsArray = [];
  for (let i = 0; i < daysOfMonth.length; i++) {
    if (daysOfMonth[i].value !== 'Итого за неделю') {
      rowsArray.push({ date: daysOfMonth[i].value, expenseName: '', expenseSum: '' });
    } else if (daysOfMonth[i].value === 'Итого за месяц') {
      rowsArray.push({ date: daysOfMonth[i].value, expenseName: '', expenseSum: '' });
    } else if (daysOfMonth[i].value === 'Итого за неделю') {
      rowsArray.push({ date: daysOfMonth[i].value, expenseName: '', expenseSum: '' });
    }
  }
  return rowsArray;
}

export function createMoneyTableData(pickedDate, tableHeaders) {
  const daysOfMonth = createDaysOfMonth(pickedDate);
  let rowsArray = [];
  for (let i = 0; i < daysOfMonth.length; i++) {
    if (daysOfMonth[i].value !== 'Итого за неделю') {
      let genData = {};
      tableHeaders.map(row => {
        if (row.key !== 'date') {
          genData['date'] = daysOfMonth[i].value;
          genData[row.key] = '';
        }
      });
      rowsArray.push(genData);
    } else if (daysOfMonth[i].value === 'Итого за месяц') {
      let genData = {};
      tableHeaders.map(row => {
        if (row.key !== 'date') {
          genData['date'] = daysOfMonth[i].value;
          genData[row.key] = '';
        }
      });
      rowsArray.push(genData);
    } else if (daysOfMonth[i].value === 'Итого за неделю') {
      let genData = {};
      tableHeaders.map(row => {
        if (row.key !== 'date') {
          genData['date'] = daysOfMonth[i].value;
          genData[row.key] = '';
        }
      });
      rowsArray.push(genData);
    }
  }
  return rowsArray;
}

export function createOrdersTableData(pickedDate) {
  const daysOfMonth = createDaysOfMonth(pickedDate);
  let rowsArray = [];

  for (let i = 0; i < daysOfMonth.length; i++) {
    if (daysOfMonth[i].value !== 'Итого за неделю') {
      let genData = {};
      genData['date'] = daysOfMonth[i].value;
      rowsArray.push(genData);
    } else if (daysOfMonth[i].value === 'Итого за месяц') {
      let genData = {};
      genData['date'] = daysOfMonth[i].value;
      rowsArray.push(genData);
    } else if (daysOfMonth[i].value === 'Итого за неделю') {
      let genData = {};
      genData['date'] = daysOfMonth[i].value;
      rowsArray.push(genData);
    }
  }
  return rowsArray;
}

export function updateTotalRowsMain(indexes, state, row) {
  let totalEarnings = 0;
  let totalCosts = 0;
  let totalMargin = 0;
  let totalAcquiring = 0;
  let totalCashbox = 0;
  let monthTotal = {
    acquiring: 0,
    cashbox: 0,
    earnings: 0,
    costs: 0,
    margin: 0,
  };
  let rowLength = state.length - 1;

  function calculateTotalRows(state, n) {
    totalAcquiring += +state[n]['acquiring'];
    totalCashbox += +state[n]['pointCashbox'];
    totalEarnings += +state[n]['earnings'];
    totalCosts += +state[n]['costs'];
    totalMargin += +state[n]['margin'];
  }

  function updateRows(totalAcquiring, totalCashbox, totalEarnings, totalCosts, totalMargin, totalRowIndex) {
    if (!isNaN(totalEarnings) && !isNaN(totalCosts) && !isNaN(totalMargin)) {
      state[totalRowIndex]['acquiring'] = totalAcquiring;
      state[totalRowIndex]['pointCashbox'] = totalCashbox;
      state[totalRowIndex]['earnings'] = totalEarnings;
      state[totalRowIndex]['costs'] = totalCosts;
      state[totalRowIndex]['margin'] = totalMargin;
    }
  }

  function updateMonthRow() {
    indexes.map(rowIndex => {
      let monthAcquiringIndex = state[rowIndex]['acquiring'];
      let monthCashboxIndex = state[rowIndex]['pointCashbox'];
      let monthEarningIndex = state[rowIndex]['earnings'];
      let monthCostsIndex = state[rowIndex]['costs'];
      let monthMarginIndex = state[rowIndex]['margin'];
      monthTotal.acquiring = monthTotal.acquiring + +monthAcquiringIndex;
      monthTotal.cashbox = monthTotal.cashbox + +monthCashboxIndex;
      monthTotal.earnings = monthTotal.earnings + +monthEarningIndex;
      monthTotal.costs = monthTotal.costs + +monthCostsIndex;
      monthTotal.margin = monthTotal.margin + +monthMarginIndex;
    });
    state[rowLength]['acquiring'] = monthTotal.acquiring;
    state[rowLength]['pointCashbox'] = monthTotal.cashbox;
    state[rowLength]['earnings'] = monthTotal.earnings;
    state[rowLength]['costs'] = monthTotal.costs;
    state[rowLength]['margin'] = monthTotal.margin;
  }

  function closestIndex(arr, closestTo) {
    let closest = Math.max.apply(null, arr); //Get the highest number in arr in case it match nothing.
    let closestIndex = 0;
    for (let i = 0; i < arr.length; i++) {
      //Loop the array
      if (arr[i] >= closestTo && arr[i] <= closest) {
        closest = arr[i];
        closestIndex = i;
      } //Check if it's higher than your number, but lower than your closest value
    }
    return closestIndex; // return the value
  }

  let ind = closestIndex(indexes, row);
  let start = 0;

  if (ind === 0) {
    start = 0;
  } else {
    start = indexes[ind - 1] + 1;
  }

  for (let n = start; n < indexes[ind]; n++) {
    calculateTotalRows(state, n);
  }

  updateRows(totalAcquiring, totalCashbox, totalEarnings, totalCosts, totalMargin, indexes[ind]);
  updateMonthRow();

  return state;
}

export function updateTotalExpenses(indexes, state, row) {
  let totalSum = 0;
  let monthTotal = {
    sum: 0,
  };
  let rowLength = state.length - 1;

  function calculateTotalRows(state, n) {
    totalSum += +state[n]['expenseSum'];
  }

  function updateRows(totalSum, totalRowIndex) {
    if (!isNaN(totalSum)) {
      state[totalRowIndex]['expenseSum'] = totalSum;
    }
  }

  function updateMonthRow() {
    indexes.map(rowIndex => {
      let monthSumIndex = state[rowIndex]['expenseSum'];
      monthTotal.sum = monthTotal.sum + +monthSumIndex;
    });
    state[rowLength]['expenseSum'] = monthTotal.sum;
  }

  function closestIndex(arr, closestTo) {
    let closest = Math.max.apply(null, arr); //Get the highest number in arr in case it match nothing.
    let closestIndex = 0;
    for (let i = 0; i < arr.length; i++) {
      //Loop the array
      if (arr[i] >= closestTo && arr[i] <= closest) {
        closest = arr[i];
        closestIndex = i;
      } //Check if it's higher than your number, but lower than your closest value
    }
    return closestIndex; // return the value
  }

  let ind = closestIndex(indexes, row);
  let start = 0;

  if (ind === 0) {
    start = 0;
  } else {
    start = indexes[ind - 1] + 1;
  }

  for (let n = start; n < indexes[ind]; n++) {
    calculateTotalRows(state, n);
  }

  updateRows(totalSum, indexes[ind]);
  updateMonthRow();

  return state;
}

export function updateTotalRowsMoney(indexes, state, row, column) {
  let totalSum = 0;
  let totalMargin = 0;
  let totalCosts = 0;
  let monthTotal = {
    sum: 0,
    margin: 0,
    costs: 0,
  };
  let rowLength = state.length - 1;

  function calculateTotalRows(state, n) {
    totalSum += +state[n][column];
    totalMargin += +state[n]['margin'];
    totalCosts += +state[n]['totalCosts'];
  }

  function updateRows(totalSum, totalMargin, totalCosts, totalRowIndex) {
    state[totalRowIndex][column] = totalSum;
    state[totalRowIndex]['margin'] = totalMargin;
    state[totalRowIndex]['totalCosts'] = totalCosts;
  }

  function updateMonthRow() {
    indexes.map(rowIndex => {
      let monthSumIndex = state[rowIndex][column];
      let monthMarginIndex = state[rowIndex]['margin'];
      let monthCostsIndex = state[rowIndex]['totalCosts'];
      monthTotal.sum = monthTotal.sum + +monthSumIndex;
      monthTotal.margin = monthTotal.margin + +monthMarginIndex;
      monthTotal.costs = monthTotal.costs + +monthCostsIndex;
    });
    state[rowLength][column] = monthTotal.sum;
    state[rowLength]['margin'] = monthTotal.margin;
    state[rowLength]['totalCosts'] = monthTotal.costs;
  }

  function closestIndex(arr, closestTo) {
    let closest = Math.max.apply(null, arr); //Get the highest number in arr in case it match nothing.
    let closestIndex = 0;
    for (let i = 0; i < arr.length; i++) {
      //Loop the array
      if (arr[i] >= closestTo && arr[i] <= closest) {
        closest = arr[i];
        closestIndex = i;
      } //Check if it's higher than your number, but lower than your closest value
    }
    return closestIndex; // return the value
  }

  let ind = closestIndex(indexes, row);
  let start = 0;

  if (ind === 0) {
    start = 0;
  } else {
    start = indexes[ind - 1] + 1;
  }

  for (let n = start; n < indexes[ind]; n++) {
    calculateTotalRows(state, n);
  }

  if (column !== 'nightSellerName' && column !== 'daySellerName') {
    updateRows(totalSum, totalMargin, totalCosts, indexes[ind]);
    updateMonthRow();
  }

  return state;
}

export function createNestedHeaders(currentHeaders, pickedDate) {
  let nestedHeaders = [];
  let nestedHeadersLabel = [];
  let nestedHeadersColumns = [];
  let columns = [];

  currentHeaders.map(header => {
    let september18 = moment('2018-09-01');
    let latinOrderName;
    //Small hack for old data format
    if (september18 > moment(pickedDate)) {
      latinOrderName = cyrToLat().transform(header.value);
    } else {
      latinOrderName = cyrToLat()
        .transform(header.value)
        .replace(' ', '-');
    }
    columns.push(
      { data: latinOrderName + '_Num' },
      { data: latinOrderName + '_Order' },
      { data: latinOrderName + '_Return' },
      { data: latinOrderName + '_Balance' },
      { data: latinOrderName + '_ExtraCharge' }
    );
    nestedHeadersLabel.push({ label: header.value, colspan: 5 });
    nestedHeadersColumns.push('№ накладной', 'Заказ', 'Возврат', 'Остаток долга', 'Заказ с наценкой');
  });

  nestedHeaders.push(nestedHeadersLabel, nestedHeadersColumns);

  return {
    nestedHeaders,
    columns,
  };
}

export function formSummaryData(rows, format = 'month_days', month) {
  const weekDateFormat = moment(month).format('MMM-YY');
  let usersFilter = [];
  rows.map(row => {
    usersFilter.push({
      text: `${row.entity.name}`,
      value: `${row.entity.name}`,
    });
  });
  let columnsArray = [
    {
      title: 'Пользователи',
      dataIndex: 'users',
      key: 'users',
      width: 260,
      fixed: 'left',
      sorter: (a, b) => a['users'].localeCompare(b['users']),
    },
  ];
  if (format === 'month_days') {
    rows[0].data.map(col => {
      columnsArray.push({
        key: col.date,
        dataIndex: col.date,
        title: col.date,
        sorter: (a, b) => {
          let tempA = a[col.date];
          let tempB = b[col.date];

          if (tempA && tempA !== undefined) {
            tempA = tempA.replace(/ /g, '');
          } else {
            tempA = 0;
          }
          if (tempB && tempB !== undefined) {
            tempB = tempB.replace(/ /g, '');
          } else {
            tempB = 0;
          }
          return +tempA - +tempB;
        },
        width: 92,
      });
    });
  } else if (format === 'month_weeks') {
    rows[0].data.map(col => {
      columnsArray.push({
        key: `week${col.week}`,
        dataIndex: col.week,
        title: `${weekDateFormat} (Неделя ${col.week})`,
        sorter: (a, b) => {
          let tempA = a[col.week];
          let tempB = b[col.week];

          if (tempA && tempA !== undefined) {
            tempA = tempA.replace(/ /g, '');
          } else {
            tempA = 0;
          }
          if (tempB && tempB !== undefined) {
            tempB = tempB.replace(/ /g, '');
          } else {
            tempB = 0;
          }
          return +tempA - +tempB;
        },
        width: 'auto',
      });
    });
  } else if (format === 'year_months') {
    rows[0].data.map(col => {
      const monthDateFormat = moment(month)
        .month(col.month - 1)
        .format('MMMM-YY');

      columnsArray.push({
        key: `month${col.month}`,
        dataIndex: col.month,
        title: monthDateFormat,
        sorter: (a, b) => {
          let tempA = a[col.month];
          let tempB = b[col.month];

          if (tempA && tempA !== undefined) {
            tempA = tempA.replace(/ /g, '');
          } else {
            tempA = 0;
          }
          if (tempB && tempB !== undefined) {
            tempB = tempB.replace(/ /g, '');
          } else {
            tempB = 0;
          }
          return +tempA - +tempB;
        },
        width: 'auto',
      });
    });
  }

  columnsArray.push({
    key: 'summary',
    dataIndex: 'summary',
    title: 'Итого',
    sorter: (a, b) => {
      let tempA = a.summary;
      let tempB = b.summary;

      if (tempA && tempA !== undefined) {
        tempA = tempA.replace(/ /g, '');
      } else {
        tempA = 0;
      }
      if (tempB && tempB !== undefined) {
        tempB = tempB.replace(/ /g, '');
      } else {
        tempB = 0;
      }
      return +tempA - +tempB;
    },
    width: 'auto',
  });
  return columnsArray;
}
