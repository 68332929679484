import React, { Component } from 'react';
import { Button, Input, Form, Icon, message } from 'antd';
import { connect } from 'react-redux';
import { userAuth, setData } from '../../modules/redux/actions/auth';
import Logo from '../../images/login/login-logo.png';
import 'antd/lib/form/style/css';
import 'antd/lib/message/style/css';
import './Login.scss';

const FormItem = Form.Item;

class LoginForm extends Component {
  state = {
    loading: false,
  };
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      const user = { username: values.username, password: values.password };
      if (!err) {
        this.setState({ loading: true });
        this.props
          .userAuth(user)
          .then(async res => {
            await this.props.setData({
              token: res.data.key,
              user: res.data,
            });
            await localStorage.setItem('token', res.data.key);
            await localStorage.setItem('user', JSON.stringify(res.data));
            this.setState({ loading: false });
            if (res.data.is_superuser) {
              this.props.history.replace('/summary');
            } else {
              this.props.history.replace('/');
            }
          })
          .catch(error => {
            this.setState({ loading: false });
            if (error.response) {
              if (
                error.response.status === 404 ||
                error.response.status === 500 ||
                error.response.status === 405 ||
                error.response.status === 503
              ) {
                message.error('Сервер не доступен');
              } else {
                Object.keys(error.response.data).map(key => {
                  message.error(error.response.data[key]);
                });
              }
            }
          });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="login-screen">
        <div className="container container-height_full">
          <div className="login-form_container">
            <div className="login-form_content">
              <div className="login-form_logo">
                <img src={Logo} alt="logo" className="img-fluid logo-img" />
              </div>
              <Form onSubmit={this.handleSubmit} className="login-form">
                <h6>Авторизация</h6>
                <FormItem>
                  {getFieldDecorator('username', {
                    rules: [{ required: true, message: 'Введите ваш логин' }],
                  })(
                    <Input
                      className="form-input"
                      size="large"
                      prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      placeholder="Имя пользователя"
                    />
                  )}
                </FormItem>

                <FormItem>
                  {getFieldDecorator('password', {
                    rules: [{ required: true, message: 'Введите пароль' }],
                  })(
                    <Input
                      className="form-input"
                      size="large"
                      prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      type="password"
                      placeholder="Пароль"
                    />
                  )}
                </FormItem>
                <FormItem>
                  <Button
                    loading={this.state.loading}
                    htmlType="submit"
                    className="login-form-button login-form-button_submit"
                  >
                    Войти
                  </Button>
                </FormItem>
              </Form>
              <div className="login-form-btn_back">
                <Button type="primary" className="login-form-button_back" href="http://24vol.ru/">
                  Перейти на сайт
                </Button>
              </div>
            </div>
            <div className="login-form_background">
              <span className="copyright">&#169; dpsoft, 2018</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    token: state.auth.token,
    error: state.auth.error,
  };
}

const Login = Form.create()(LoginForm);

export default connect(
  mapStateToProps,
  { userAuth, setData }
)(Login);
