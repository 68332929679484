import React, { Component } from 'react';
import Loadable from 'react-loadable';
import { Route, Switch, withRouter } from 'react-router-dom';
import Home from '../pages/Home/Home';
import Login from '../pages/Login/Login';
import NoMatch from '../pages/NoMatch/NoMatch';
import PrivateRoute from '../components/PrivateRoute/PrivateRoute';
import AdminRoute from '../components/AdminRoute/AdminRoute';
import ComponentPreloader from '../components/ComponentPreloader/ComponentPreloader';

const LoadableSummary = Loadable({
  loader: () => import('../pages/Summary/Summary'),
  loading: ComponentPreloader,
});

class Root extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/login" component={Login} />
        <PrivateRoute exact path="/" component={Home} />
        <AdminRoute exact path="/summary" component={LoadableSummary} />
        <Route component={NoMatch} />
      </Switch>
    );
  }
}

export default withRouter(Root);
