import React, { Component } from 'react';
import serverError from '../../images/500.svg';
import './errorBoundary.scss';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  getBrowserInfo = () => {
    let ua = navigator.userAgent;
    let tem;
    let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return { name: 'IE', version: tem[1] || '' };
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) return { name: tem[1].replace('OPR', 'Opera'), version: tem[2] };
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
      M.splice(1, 1, tem[1]);
    }
    return {
      name: M[0],
      version: M[1],
    };
  };

  componentDidCatch(error, info) {
    const browserInfo = this.getBrowserInfo();
    let userInfo = {};
    if (this.props.data) {
      userInfo = this.props.data.user;
    }
    const errorInfo = {
      trace: error,
      errorInfo: info,
      browserInfo: browserInfo,
      url: window.location.href,
      userInfo: userInfo,
    };
    // You can also log the error to an error reporting service
    this.props.handleErrorBoundary(errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error-boundary">
          <img src={serverError} alt="error-server" />
          <h2>Упс!</h2>
          <p>Что-то пошло не так, пожалуйста, обновите страницу и попробуйте еще раз</p>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
