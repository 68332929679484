import React from 'react';
import notFound from '../../images/404.svg';
import './nomatch.scss';

function NoMatch() {
  return (
    <div className="page-notfound">
      <div className="error-500">
        <img src={notFound} alt="error-server" />
        <h2>404</h2>
        <p>Страница не найдена, пожалуйста, проверьте правильность введенного адреса</p>
      </div>
    </div>
  );
}

export default NoMatch;
