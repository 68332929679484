import { combineReducers } from 'redux';

import auth from './auth';
import common from './common';
import header from './header';
import mainTable from './mainTable';
import expenseTable from './expenseTable';
import moneyTable from './moneyTable';
import ordersTable from './ordersTable';
import summary from './summary';

export default combineReducers({
  auth,
  common,
  header,
  mainTable,
  expenseTable,
  moneyTable,
  ordersTable,
  summary,
});
