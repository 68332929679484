import jsPDF from 'jspdf';
import 'jspdf-autotable';
import './OpenSans-Regular-normal';
import { cyrToLat } from './cyrToLat';

export default function print(header, rows, callback) {
  if (!!header && header.length > 0 && (!!rows && rows.length > 0)) {
    try {
      const headers = header.map(entry =>
        cyrToLat()
          .transform(entry.value)
          .replace('«', '')
          .replace(' ', '-')
      );
      const doc = new jsPDF();
      headers.map((Head, key) => {
        doc.setFont('OpenSans-Regular');
        doc.setFontSize(18);
        doc.text(header[key].value, 14, 15);
        doc.setFontSize(12);
        doc.autoTable({
          alternateRowStyles: {
            fillColor: [255, 255, 255],
          },
          headStyles: {
            cellWidth: 'wrap',
            font: 'OpenSans-Regular',
            cellPadding: { top: 2, bottom: 2 },
            fillColor: [255, 255, 255],
            textColor: [0, 0, 0],
            fontSize: 12,
          },
          startY: 20,
          styles: {
            halign: 'center',
            valgin: 'center',
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
            font: 'OpenSans-Regular',
            cellPadding: 0.7,
          },
          bodysStyle: {
            font: 'OpenSans-Regular',
            fontSize: 12,
            cellPadding: 0.7,
          },
          willDrawCell: data => {
            if (data.row.index === 0) {
              doc.setFontStyle('regular');
              doc.setFont('OpenSans-Regular');
            }
          },
          head: [['Дата', '№ накладной', 'Заказ', 'Возврат', 'Остаток долга', 'Заказ с наценкой']],
          body: rows.map((row, rowKey) => {
            return [
              !!row[`date`] ? row[`date`] : '',
              row[`${Head}_Num`] !== undefined ? row[`${Head}_Num`] : '',
              row[`${Head}_Order`] !== undefined && row[`${Head}_Order`] !== null
                ? (+row[`${Head}_Order`]).toFixed(2) + ' р.'
                : '',
              row[`${Head}_Return`] !== undefined && row[`${Head}_Return`] !== null
                ? (+row[`${Head}_Return`]).toFixed(2) + ' р.'
                : '',
              row[`${Head}_Balance`] !== undefined && row[`${Head}_Balance`] !== null
                ? (+row[`${Head}_Balance`]).toFixed(2) + ' р.'
                : '',
              row[`${Head}_ExtraCharge`] !== undefined && row[`${Head}_ExtraCharge`] !== null
                ? (+row[`${Head}_ExtraCharge`]).toFixed(2) + ' р.'
                : '',
            ];
          }),
        });
        if (key + 1 < headers.length) {
          doc.addPage();
        }
      });
      callback('success', '');
      doc.output('dataurlnewwindow');
    } catch (err) {
      console.log(err);
      callback('error', 'Произошла ошибка, повторите позже');
    }
  } else {
    callback('error', 'Таблица пуста');
  }
}
