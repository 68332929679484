import React, { Component } from 'react';
import { connect } from 'react-redux';
import { HotTable } from 'react-handsontable/dist/ce/react-handsontable';
import Handsontable from 'handsontable';
import { initTable, updateTable } from '../../modules/redux/actions/expenseTable';
import { includes } from 'lodash';
import './tableExpense.scss';

class TableExpense extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  async componentDidMount() {
    await this.props.initTable();
    this.setState({
      loading: false,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pickedDate !== this.props.pickedDate) {
      this.props.initTable();
    }

    if (prevProps.activePoint !== this.props.activePoint) {
      this.props.initTable();
    }
  }

  percentRenderer(instance, td, row, col, prop, value, cellProperties) {
    Handsontable.renderers.NumericRenderer.apply(this, arguments);
  }

  render() {
    const { tableData, rowHeaders, totalRowsIndexes } = this.props;
    return (
      <div className="table-container table-expense">
        <div className="table-expense_container">
          <HotTable
            settings={{
              data: tableData,
              rowHeaders: rowHeaders,
              stretchH: 'all',
              rowHeaderWidth: 140,
              renderAllRows: true,
              colHeaders: ['Наименование расхода', 'Сумма'],
              className: 'htCenter',
              columns: [{ data: 'expenseName', type: 'text' }, { data: 'expenseSum' }],
              fillHandle: false,
              cells: function(row, column, prop) {
                let cellProperties = {};
                if (prop === 'expenseSum') {
                  cellProperties.type = 'numeric';
                  cellProperties.numericFormat = {
                    pattern: '$0,0.00',
                    culture: 'ru-RU',
                  };
                }

                if (includes(totalRowsIndexes, row) || row === tableData.length - 1) {
                  cellProperties.readOnly = true;
                }

                return cellProperties;
              },
            }}
            maxRows={tableData.length}
            beforeChange={changes => this.props.updateTable(changes)}
            renderer={this.percentRenderer}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    pickedDate: state.common.pickedDate,
    activePoint: state.common.activePoint,
    tableData: state.expenseTable.tableData,
    rowHeaders: state.expenseTable.rowHeaders,
    totalRowsIndexes: state.expenseTable.totalRowsIndexes,
  };
}

export default connect(
  mapStateToProps,
  {
    initTable,
    updateTable,
  }
)(TableExpense);
