import React, { Component } from 'react';
import { connect } from 'react-redux';
import { HotTable } from 'react-handsontable/dist/ce/react-handsontable';
import Handsontable from 'handsontable';
import { initTable, updateTable, checkData } from '../../modules/redux/actions/moneyTable';
import './tableMoney.scss';
import { includes } from 'lodash';

class TableMoney extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      loading: true,
    };
  }

  async componentDidMount() {
    await this.props.initTable();
    this.setState({
      loading: false,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pickedDate !== this.props.pickedDate) {
      this.props.initTable();
    }

    if (prevProps.activePoint !== this.props.activePoint) {
      this.props.initTable();
    }

    if (prevProps.tableMainData !== this.props.tableMainData) {
      this.props.checkData(this.props.tableMainData);
      return false;
    }

    if (prevProps.tableExpenseData !== this.props.tableExpenseData) {
      this.props.checkData(this.props.tableExpenseData);
      return false;
    }
  }

  percentRenderer(instance, td, row, col, prop, value, cellProperties) {
    Handsontable.renderers.NumericRenderer.apply(this, arguments);
  }

  render() {
    const { tableData, rowHeaders, colHeaders, columns, sidebarVisible, totalRowsIndexes } = this.props;
    return (
      <div className="table-container table-money">
        <div className={`table-money_container ${sidebarVisible ? 'sidebar-visible' : ''}`}>
          <HotTable
            settings={{
              data: tableData,
              rowHeaders: rowHeaders,
              stretchH: 'all',
              rowHeaderWidth: 140,
              colHeaders: colHeaders,
              className: 'htCenter',
              columns: columns,
              fillHandle: false,
              beforePaste: function(data, coords) {
                console.log(coords);
              },
              cells: function(row, column, prop) {
                let cellProperties = {};
                if (
                  prop !== 'nightSellerName' &&
                  prop !== 'daySellerName' &&
                  prop !== 'dayAverageCheck' &&
                  prop !== 'nightAverageCheck' &&
                  prop !== 'dayBuys' &&
                  prop !== 'nightBuys'
                ) {
                  if (
                    prop === 'earnings' ||
                    prop === 'costs' ||
                    prop === 'margin' ||
                    prop === 'expenseSum' ||
                    prop === 'totalCosts'
                  ) {
                    cellProperties.readOnly = true;
                  }
                  cellProperties.type = 'numeric';
                  cellProperties.numericFormat = {
                    pattern: '$0,0.00',
                    culture: 'ru-RU',
                  };
                } else if (prop === 'dayAverageCheck' || prop === 'nightAverageCheck') {
                  cellProperties.type = 'numeric';
                  cellProperties.numericFormat = {
                    pattern: '$0,0.00',
                    culture: 'ru-RU',
                  };
                } else {
                  cellProperties.type = 'text';
                }

                if (includes(totalRowsIndexes, row) || row === tableData.length - 1) {
                  cellProperties.readOnly = true;
                }

                return cellProperties;
              },
            }}
            maxRows={tableData.length}
            beforeChange={changes => this.props.updateTable(changes)}
            renderer={this.percentRenderer}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    pickedDate: state.common.pickedDate,
    activePoint: state.common.activePoint,
    tableData: state.moneyTable.tableData,
    rowHeaders: state.moneyTable.rowHeaders,
    tableHeaders: state.moneyTable.tableHeaders,
    columns: state.moneyTable.columns,
    colHeaders: state.moneyTable.colHeaders,
    totalRowsIndexes: state.moneyTable.totalRowsIndexes,
    sidebarVisible: state.common.sidebarVisible,
    tableMainData: state.mainTable.tableData,
    tableExpenseData: state.expenseTable.tableData,
  };
}

export default connect(
  mapStateToProps,
  {
    initTable,
    updateTable,
    checkData,
  }
)(TableMoney);
