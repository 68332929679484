export function initTable(actionType = 'init') {
  return (dispatch, getState) => {
    const { common } = getState();
    dispatch({
      type: 'INIT_TABLE_DATA',
      payload: {
        pickedDate: common.pickedDate,
        mainTable: common.mainTable,
        actionType: actionType,
      },
    });
  };
}

export function updateTable(changes) {
  return dispatch => {
    dispatch({
      type: 'UPDATE_TABLE_DATA',
      payload: changes,
    });

    dispatch({
      type: 'DATA_CHANGED',
    });
  };
}

export function syncTable(data, type) {
  return dispatch => {
    dispatch({
      type: 'SYNC_TABLE_DATA',
      payload: data,
      tableType: type,
    });
  };
}
