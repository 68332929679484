import { filter, find, findIndex, isEmpty } from 'lodash';
import { createOrdersTableData, createNestedHeaders } from '../../../util/tableHelpers';

const initialState = {
  tableData: [],
  rowHeaders: [],
  tableHeaders: [],
  columns: [],
  nestedHeaders: [],
  prevMonthData: null,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'INIT_ORDER_TABLE_DATA':
      let initTableData;
      let columnsInit = [];
      let headersInit = [];
      let tableHeaders = state.tableHeaders.slice();
      let orderTable = action.payload.ordersTable;
      if (orderTable && orderTable.rows) {
        initTableData = orderTable.rows;
      } else {
        initTableData = createOrdersTableData(action.payload.pickedDate);
      }
      if (orderTable && orderTable.headers) {
        tableHeaders = orderTable.headers;
        let createdHeaders = createNestedHeaders(tableHeaders, action.payload.pickedDate);
        columnsInit = createdHeaders.columns;
        headersInit = createdHeaders.nestedHeaders;
      } else {
        tableHeaders = [];
      }

      const rowHeaders = initTableData.map(data => {
        return data.date;
      });

      return {
        ...state,
        tableData: initTableData,
        tableHeaders: tableHeaders,
        rowHeaders: rowHeaders,
        columns: columnsInit,
        nestedHeaders: headersInit,
        pickedDate: action.payload.pickedDate,
      };

    case 'UPDATE_ORDER_TABLE_DATA':
      let row = action.payload[0][0];
      let column = action.payload[0][1];
      let newValue = action.payload[0][3];
      let tableData = state.tableData.slice();
      let columnName = column.split('_');
      let pointId = action.pointId;
      let tempPrevMonthData = state.prevMonthData;
      tableData[row][column] = newValue;

      if (columnName[1] === 'Order') {
        if (tableData[row][columnName[0] + '_Num']) {
          if (!tableData[row][columnName[0] + '_Return']) {
            tableData[row][columnName[0] + '_Balance'] = newValue;
            tableData.map((rowEl, i) => {
              if (i !== row && rowEl[columnName[0] + '_Num'] === tableData[row][columnName[0] + '_Num']) {
                if (rowEl[columnName[0] + '_Return']) {
                  tableData[row][columnName[0] + '_Balance'] = +newValue - +tableData[columnName[0] + '_Return'];
                  tableData[columnName[0] + '_Balance'] = +newValue - +tableData[columnName[0] + '_Return'];
                }
              }
            });
          } else {
            tableData[row][columnName[0] + '_Balance'] = +newValue - +tableData[row][columnName[0] + '_Return'];
          }
        }
      }

      if (columnName[1] === 'Return') {
        if (tableData[row][columnName[0] + '_Num']) {
          if (!tableData[row][columnName[0] + '_Order']) {
            let lastMonthBalance = 0;
            let lastMonthOrder = 0; // eslint-disable-line no-unused-vars
            if (tempPrevMonthData && !isEmpty(tempPrevMonthData)) {
              let activePointIndex = findIndex(tempPrevMonthData.points, o => o.id === pointId);
              let prevMonthActivePoint = tempPrevMonthData.points[activePointIndex];
              if (
                prevMonthActivePoint &&
                prevMonthActivePoint.tables &&
                prevMonthActivePoint.tables.order &&
                prevMonthActivePoint.tables.order.rows
              ) {
                prevMonthActivePoint.tables.order.rows.map(rowTmp => {
                  if (rowTmp[columnName[0] + '_Num'] === tableData[row][columnName[0] + '_Num']) {
                    if (rowTmp[columnName[0] + '_Order']) {
                      lastMonthOrder = rowTmp[columnName[0] + '_Order'];
                    }
                    lastMonthBalance = rowTmp[columnName[0] + '_Balance'];
                  }
                });
              }
            }
            let sum = 0;
            tableData.map((rowEl, i) => {
              if (i !== row && rowEl[columnName[0] + '_Num'] === tableData[row][columnName[0] + '_Num']) {
                if (rowEl[columnName[0] + '_Return']) {
                  sum += rowEl[columnName[0] + '_Return'];
                }
              }
            });
            if (lastMonthBalance) {
              tableData[row][columnName[0] + '_Balance'] = lastMonthBalance - +newValue;
            } else {
              if (!tableData[row][columnName[0] + '_Order'] && !newValue) {
                tableData[row][columnName[0] + '_Balance'] = '';
              }
            }
            tableData.map((rowEl, i) => {
              if (i !== row && rowEl[columnName[0] + '_Num'] === tableData[row][columnName[0] + '_Num']) {
                if (rowEl[columnName[0] + '_Order']) {
                  tableData[row][columnName[0] + '_Balance'] = +rowEl[columnName[0] + '_Order'] - +newValue - sum;
                  rowEl[columnName[0] + '_Balance'] = +rowEl[columnName[0] + '_Order'] - +newValue - sum;
                }
                if (rowEl[columnName[0] + '_Return']) {
                  if (lastMonthBalance) {
                    tableData[row][columnName[0] + '_Balance'] = lastMonthBalance - +newValue - sum;
                    rowEl[columnName[0] + '_Balance'] = lastMonthBalance - +newValue - sum;
                  } else {
                    rowEl[columnName[0] + '_Balance'] = tableData[row][columnName[0] + '_Balance'];
                  }
                }
              }
            });
          } else {
            tableData.map((rowEl, i) => {
              if (i !== row && rowEl[columnName[0] + '_Num'] === tableData[row][columnName[0] + '_Num']) {
                if (rowEl[columnName[0] + '_Return']) {
                  console.log(rowEl[columnName[0] + '_Return']);
                }
              }
            });
            tableData[row][columnName[0] + '_Balance'] = +tableData[row][columnName[0] + '_Order'] - +newValue;
          }
        }
      }

      return {
        ...state,
        tableData: tableData,
      };

    case 'RESET_STATE':
      return initialState;

    case 'ADD_NEW_ORDER':
      let currentHeaders = state.tableHeaders.slice();
      let columns = [];
      let nestedHeaders = [];
      let tempData = state.tableData.slice();

      currentHeaders.push({
        value: action.payload,
        readOnly: true,
        key: 'orderName',
      });

      if (!tempData.length) {
        tempData = createOrdersTableData(state.pickedDate);
      }

      let createdHeaders = createNestedHeaders(currentHeaders, action.payload.pickedDate);

      columns = createdHeaders.columns;
      nestedHeaders = createdHeaders.nestedHeaders;

      return {
        ...state,
        tableHeaders: currentHeaders,
        nestedHeaders: nestedHeaders,
        columns: columns,
        tableData: tempData,
      };

    case 'DELETE_ORDER':
      const orderName = action.payload;
      let nestedHeadersTemp = [];
      let columnsTemp = [];
      let tempHeaders = filter(state.tableHeaders, function(o) {
        return o.value !== orderName;
      });

      let tempRows = state.tableData.slice().map(row => {
        delete row[orderName + '_Balance'];
        delete row[orderName + '_Num'];
        delete row[orderName + '_Order'];
        delete row[orderName + '_Return'];
        return row;
      });

      let headersFiltered = createNestedHeaders(tempHeaders, action.payload.pickedDate);

      columnsTemp = headersFiltered.columns;
      nestedHeadersTemp = headersFiltered.nestedHeaders;

      return {
        ...state,
        tableHeaders: tempHeaders,
        tableData: tempRows,
        nestedHeaders: nestedHeadersTemp,
        columns: columnsTemp,
      };

    case 'GET_PREV_PROVIDERS':
      const prevMonthData = state.prevMonthData;
      const activePoint = action.activePoint;
      let prevMonthHeaders = [];
      let newColumns = [];
      let newHeaders = [];
      let newTableData = [];
      if (prevMonthData && prevMonthData.points && prevMonthData.points.length) {
        let activePointData = find(prevMonthData.points, o => o.name === activePoint.name);
        if (
          activePointData &&
          activePointData.tables &&
          activePointData.tables.order &&
          activePointData.tables.order.rows
        ) {
          prevMonthHeaders = activePointData.tables.order.headers;
          let headersFiltered = createNestedHeaders(prevMonthHeaders, action.pickedDate);
          newTableData = createOrdersTableData(action.pickedDate);
          newColumns = headersFiltered.columns;
          newHeaders = headersFiltered.nestedHeaders;
        }
      }

      return {
        ...state,
        tableHeaders: prevMonthHeaders,
        nestedHeaders: newHeaders,
        tableData: newTableData,
        columns: newColumns,
      };

    case 'GET_PREV_MONTH_DATA':
      return {
        ...state,
        prevMonthData: action.payload,
      };

    default:
      return state;
  }
};
