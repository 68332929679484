import moment from 'moment';
import axios from 'axios';

export function initTable(actionType = 'init') {
  return (dispatch, getState) => {
    const { common } = getState();
    dispatch({
      type: 'INIT_ORDER_TABLE_DATA',
      payload: {
        pickedDate: common.pickedDate,
        ordersTable: common.ordersTable,
        actionType: actionType,
      },
    });
  };
}

export function updateTable(changes, id) {
  return dispatch => {
    dispatch({
      type: 'UPDATE_ORDER_TABLE_DATA',
      payload: changes,
      pointId: id,
    });

    dispatch({
      type: 'DATA_CHANGED',
    });
  };
}

export function addNewOrder(orderName) {
  return dispatch => {
    dispatch({
      type: 'ADD_NEW_ORDER',
      payload: orderName,
    });

    dispatch({
      type: 'DATA_CHANGED',
    });
  };
}

export function deleteOrder(orderName) {
  return dispatch => {
    dispatch({
      type: 'DELETE_ORDER',
      payload: orderName,
    });

    dispatch({
      type: 'DATA_CHANGED',
    });
  };
}

export function getPrevMonthData(pickedData) {
  return dispatch => {
    let pickedDate = moment(pickedData)
      .subtract(1, 'months')
      .startOf('month')
      .format();
    axios
      .get(`/api/v1/points/?date=${encodeURIComponent(pickedDate)}`)
      .then(response => {
        dispatch({
          type: 'GET_PREV_MONTH_DATA',
          payload: response.data,
        });
      })
      .catch(function(error) {
        dispatch({
          type: 'DATA_LOADING_ERROR',
        });
      });
  };
}

export function getPrevProviders(date) {
  return (dispatch, getState) => {
    const { common } = getState();
    dispatch({
      type: 'DATA_LOADING',
    });

    dispatch({
      type: 'GET_PREV_PROVIDERS',
      pickedDate: date,
      activePoint: common.activePoint,
    });

    dispatch({
      type: 'DATA_LOADED',
    });

    dispatch({
      type: 'DATA_CHANGED',
    });
  };
}
