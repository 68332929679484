import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tabs, Spin, message } from 'antd';
import { withRouter } from 'react-router';
import axios from 'axios';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import createCustomFormat from '../../util/customFormat';
import { validateToken, setToken } from '../../modules/redux/actions/auth';
import { getData, handleErrorBoundary } from '../../modules/redux/actions/common';
import TableMain from '../../components/TableMain/TableMain';
import TableMoney from '../../components/TableMoney/TableMoney';
import TableExpense from '../../components/TableExpense/TableExpense';
import TableOrders from '../../components/TableOrders/TableOrders';
import serverError from '../../images/500.svg';
import 'antd/lib/layout/style/css';
import 'antd/lib/tabs/style/css';
import 'antd/lib/message/style/css';
import './Home.scss';

const TabPane = Tabs.TabPane;

const requestInterceptor = request => {
  const storeToken = localStorage.getItem('token');

  if (storeToken !== '' && storeToken) {
    request.headers.Authorization = `Token ${storeToken}`;
  }

  request.headers['Cache-Control'] = 'no-cache';

  return request;
};

axios.interceptors.request.use(requestInterceptor);

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };

    createCustomFormat();
  }

  async componentDidMount() {
    if (!this.props.token) {
      let token = localStorage.getItem('token');
      if (token) {
        await this.props.validateToken(token);
      }
      if (!this.props.token) {
        localStorage.clear();
        this.props.history.replace('/login');
      }
    }

    await this.props.getData();

    if (this.props.dataLoadingError) {
      message.error('Произошла ошибка при загрузке данных, перезагрузите страницу и попробуйте еще раз');
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  beforeUnload = event => {
    event.preventDefault();
    event.returnValue = '';
  };

  render() {
    const { loading } = this.state;
    const { dataLoading, dataLoadingError } = this.props;
    if (this.props.dataChanged) {
      window.addEventListener('beforeunload', this.beforeUnload);
    } else {
      window.removeEventListener('beforeunload', this.beforeUnload);
    }
    let overlayActive = dataLoading ? 'overlay_active' : '';

    return (
      <ErrorBoundary handleErrorBoundary={this.props.handleErrorBoundary}>
        <main className="container-common">
          <div className={'preloader-overlay ' + overlayActive}>
            <Spin size="large" tip="Загрузка" />
          </div>
          <Sidebar />
          <div className="main-content">
            <Header />
            {dataLoadingError ? (
              <div className="error-main">
                <img src={serverError} alt="error-server" />
                <h2>500</h2>
                <p>Ошибка сервера, пожалуйста, обновите страницу и попробуйте еще раз</p>
              </div>
            ) : loading ? (
              <div className="table-spinner_loading">
                <Spin />
              </div>
            ) : (
              <div className="tabs-container">
                <Tabs defaultActiveKey="1">
                  <TabPane tab="Данные с точек" key="1" forceRender>
                    <TableMain />
                  </TabPane>
                  <TabPane tab="Движение денег" key="2" forceRender>
                    <TableMoney />
                  </TabPane>
                  <TabPane tab="Прочие расходы" key="3" forceRender>
                    <TableExpense />
                  </TabPane>
                  <TabPane tab="Заказы" key="4" forceRender>
                    <TableOrders />
                  </TabPane>
                </Tabs>
              </div>
            )}
          </div>
        </main>
      </ErrorBoundary>
    );
  }
}

function mapStateToProps(state) {
  return {
    collapsed: state.header.collapsed,
    pickedYear: state.common.pickedYear,
    token: state.auth.token,
    dataChanged: state.common.dataChanged,
    dataLoading: state.common.dataLoading,
    dataLoadingError: state.common.dataLoadingError,
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    { validateToken, getData, setToken, handleErrorBoundary }
  )(Home)
);
