import { createMoneyTableData, updateTotalRowsMoney } from '../../../util/tableHelpers';
import { find, forEach } from 'lodash';

const initialState = {
  tableData: [],
  rowHeaders: [],
  tableHeaders: [],
  columns: [],
  colsTotal: [
    'expenseSum',
    'costs',
    'daySellerSalary',
    'nightSellerSalary',
    'rent',
    'utilities',
    'service',
    'taxes',
    'cameras',
    'security',
    'accountantSalary',
    'cashbox',
    'janitorSalary',
  ],
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'INIT_MONEY_TABLE_DATA':
      const tableHeaders = [
        { value: 'Выручка всего', readOnly: true, key: 'earnings' },
        { value: 'Чистая прибыль', readOnly: true, key: 'netProfit' },
        { value: 'Маржа', readOnly: true, key: 'margin' },
        { value: 'Расходы на товар', readOnly: true, key: 'costs' },
        { value: 'Расходы всего', readOnly: true, key: 'totalCosts' },
        { value: 'Выручка(день)', readOnly: true, key: 'dayEarnings' },
        { value: 'Количество покупок(день)', readOnly: true, key: 'dayBuys' },
        { value: 'Средний чек(день)', readOnly: true, key: 'dayAverageCheck' },
        { value: 'ФИО продавца(день)', readOnly: true, key: 'daySellerName' },
        { value: 'ЗП продавца(день)', readOnly: true, key: 'daySellerSalary' },
        { value: 'Выручка(ночь)', readOnly: true, key: 'nightEarnings' },
        { value: 'Количество покупок(ночь)', readOnly: true, key: 'nightBuys' },
        { value: 'Средний чек(ночь)', readOnly: true, key: 'nightAverageCheck' },
        { value: 'ФИО продавца(ночь)', readOnly: true, key: 'nightSellerName' },
        { value: 'ЗП продавца(ночь)', readOnly: true, key: 'nightSellerSalary' },
        { value: 'Аренда', readOnly: true, key: 'rent' },
        { value: 'Ком. Услуги', readOnly: true, key: 'utilities' },
        { value: 'Обслуживание РС', readOnly: true, key: 'service' },
        { value: 'Налоги', readOnly: true, key: 'taxes' },
        { value: 'Интернет/Камеры', readOnly: true, key: 'cameras' },
        { value: 'Охрана', readOnly: true, key: 'security' },
        { value: 'ЗП бухгалтера', readOnly: true, key: 'accountantSalary' },
        { value: 'Касса', readOnly: true, key: 'cashbox' },
        { value: 'Прочие расходы', readOnly: true, key: 'expenseSum' },
        { value: 'ЗП дворника', readOnly: true, key: 'janitorSalary' },
      ];

      let initTableData;
      if (action.payload.moneyTable && action.payload.moneyTable.length) {
        initTableData = action.payload.moneyTable.slice();
      } else {
        initTableData = createMoneyTableData(action.payload.pickedDate, tableHeaders);
      }

      let totalRowsIndexes = [];
      initTableData.map((data, index) => {
        if (data.date === 'Итого за неделю') {
          totalRowsIndexes.push(index);
        }
      });

      const rowHeaders = initTableData.map(data => {
        return data.date;
      });

      let columns = [];
      let colHeaders = [];

      tableHeaders.map(header => {
        colHeaders.push(header.value);
        columns.push({ data: header.key });
      });

      return {
        ...state,
        tableHeaders: tableHeaders,
        rowHeaders: rowHeaders,
        tableData: initTableData,
        columns: columns,
        colHeaders: colHeaders,
        totalRowsIndexes: totalRowsIndexes,
      };

    case 'UPDATE_MONEY_TABLE_DATA':
      let row = action.payload[0][0];
      let column = action.payload[0][1];
      let newValue = action.payload[0][3];
      let tableData = state.tableData.slice();
      tableData[row][column] = newValue;

      if (column === 'earnings' || column === 'costs') {
        tableData[row]['margin'] =
          +tableData[row]['earnings'] - +tableData[row]['costs']
            ? +tableData[row]['earnings'] - +tableData[row]['costs']
            : '';
      }

      if (column === 'earnings' || column === 'totalCosts') {
        tableData[row]['netProfit'] = +tableData[row]['earnings'] - +tableData[row]['totalCosts'];
      }

      if (column === 'dayEarnings' || column === 'dayBuys') {
        let dayAverageCheck = +tableData[row]['dayEarnings'] / +tableData[row]['dayBuys'];
        if (dayAverageCheck !== Infinity) {
          tableData[row]['dayAverageCheck'] = dayAverageCheck ? dayAverageCheck : 0;
          tableData = updateTotalRowsMoney(state.totalRowsIndexes, tableData, row, 'dayAverageCheck');
        }
      }

      if (column === 'nightEarnings' || column === 'nightBuys') {
        let dayAverageCheck = +tableData[row]['nightEarnings'] / +tableData[row]['nightBuys'];
        if (dayAverageCheck !== Infinity) {
          tableData[row]['nightAverageCheck'] = dayAverageCheck ? dayAverageCheck : 0;
          tableData = updateTotalRowsMoney(state.totalRowsIndexes, tableData, row, 'nightAverageCheck');
        }
      }

      let totalCosts = 0;

      state.colsTotal.map(col => {
        if (col === column) {
          state.colsTotal.map(col => {
            totalCosts += +tableData[row][col];
          });
          if (totalCosts) {
            tableData[row]['totalCosts'] = totalCosts;
            tableData[row]['netProfit'] = tableData[row]['earnings'] - tableData[row]['totalCosts'];
            tableData = updateTotalRowsMoney(state.totalRowsIndexes, tableData, row, 'netProfit');
          }
        }
      });

      let totalRow = find(state.totalRowsIndexes, function(o) {
        return o === row;
      });

      if (!totalRow) {
        updateTotalRowsMoney(state.totalRowsIndexes, tableData, row, column);
      }

      return {
        ...state,
        tableData: tableData,
      };

    case 'CHECK_MONEY_TABLE_DATA':
      const data = action.payload;
      let tableDataCheck = state.tableData.slice();
      data.map((dataRow, i) => {
        tableDataCheck.map((currentRow, j) => {
          if (i === j) {
            forEach(currentRow, (currentValue, currentKey) => {
              forEach(dataRow, (value, key) => {
                if (currentKey === key) {
                  currentRow[currentKey] = value;
                }
              });
            });
          }
        });
      });

      tableDataCheck.map(row => {
        let totalCosts = 0;
        if (!isNaN(row['costs']) && !isNaN(row['expenseSum'])) {
          state.colsTotal.map(col => {
            if (!isNaN(row[col])) {
              totalCosts += +row[col];
            }
          });
          row['totalCosts'] = totalCosts ? totalCosts : '';
        }

        if (!isNaN(row['earnings'])) {
          row['netProfit'] = row['earnings'] - row['totalCosts'] ? row['earnings'] - row['totalCosts'] : '';
        }
      });

      return {
        ...state,
        tableData: tableDataCheck,
      };

    case 'RESET_STATE':
      return initialState;

    default:
      return state;
  }
};
