export function initTable() {
  return (dispatch, getState) => {
    const { common } = getState();
    dispatch({
      type: 'INIT_MONEY_TABLE_DATA',
      payload: {
        pickedDate: common.pickedDate,
        moneyTable: common.moneyTable,
      },
    });
  };
}

export function updateTable(changes) {
  return dispatch => {
    dispatch({
      type: 'UPDATE_MONEY_TABLE_DATA',
      payload: changes,
    });

    dispatch({
      type: 'DATA_CHANGED',
    });
  };
}

export function checkData(data) {
  return dispatch => {
    dispatch({
      type: 'CHECK_MONEY_TABLE_DATA',
      payload: data,
    });
  };
}
