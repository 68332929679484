const initialState = {
  error: null,
  user: null,
  token: null,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'USER_AUTH':
      return {
        ...state,
        token: action.payload.token,
        user: JSON.parse(action.payload.user),
      };

    case 'VERIFY_RESPONSE':
      return {
        ...state,
        token: action.payload.token,
        user: action.payload.user,
        error: null,
      };

    case 'USER_LOGOUT':
      return {
        ...state,
        token: null,
        user: null,
        error: null,
      };

    case 'GET_TOKEN_FROM_COOKIES':
      return { ...state, token: action.payload, error: null };

    case 'SET_DATA':
      return {
        ...state,
        ...action.payload,
      };

    case 'USER_AUTH_ERROR':
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};
