import React, { Component } from 'react';
import { Layout, Menu, Icon } from 'antd';
import { connect } from 'react-redux';
import logo from '../../images/logo_final.png';
import logoSmall from '../../images/logo_small.png';
import { changeActivePoint, handleLoading, handleLoaded } from '../../modules/redux/actions/common';
import 'antd/lib/layout/style/css';
import 'antd/lib/menu/style/css';
import 'antd/lib/icon/style/css';
import './Sidebar.scss';

const { Sider } = Layout;

class Sidebar extends Component {
  handlePointSelect = evt => {
    this.props.handleLoading();
    //hack for preloader activating
    setTimeout(() => this.props.changeActivePoint(evt.key), 100);
    setTimeout(() => this.props.handleLoaded(), 100);
  };

  render() {
    const { points, sidebarVisible, user, activePoint } = this.props;
    const navData = points
      ? points.map(point => {
          return (
            <Menu.Item key={point.id}>
              <Icon type="shop" />
              <span>{point.name}</span>
            </Menu.Item>
          );
        })
      : null;

    return (
      <Sider trigger={null} collapsible collapsed={!sidebarVisible} width={256} className="sidebar d-print-none">
        <div className="sidebar-logo">
          {sidebarVisible ? (
            <img src={logo} alt="logo" className="img-fluid" />
          ) : (
            <img src={logoSmall} alt="logo-small" className="img-fluid" />
          )}
        </div>
        <div className="sidebar-owner">
          {user ? (
            <div className="owner-info">
              <h6>
                {user.first_name} {user.last_name}
              </h6>
              <p>{user.type}</p>
            </div>
          ) : null}
        </div>

        <Menu mode="inline" selectedKeys={[`${activePoint.id}`]} onClick={this.handlePointSelect}>
          {navData}
        </Menu>
      </Sider>
    );
  }
}

function mapStateToProps(state) {
  return {
    sidebarVisible: state.common.sidebarVisible,
    activePoint: state.common.activePoint,
    points: state.common.points,
    user: state.auth.user,
  };
}

export default connect(
  mapStateToProps,
  { changeActivePoint, handleLoading, handleLoaded }
)(Sidebar);
