import numbro from 'numbro';

export default function createCustomFormat() {
  numbro.registerLanguage({
    languageTag: 'ru-RU',
    delimiters: {
      thousands: ' ',
      decimal: ',',
    },
    abbreviations: {
      thousand: 'тыс.',
      million: 'млн',
      billion: 'млрд',
      trillion: 'трлн',
    },
    ordinal: function() {
      return '.';
    },
    currency: {
      symbol: ' ₽',
      position: 'postfix',
    },
    currencyFormat: {
      thousandSeparated: true,
      totalLength: 4,
      spaceSeparated: true,
      average: true,
    },
    formats: {
      fourDigits: {
        totalLength: 4,
        spaceSeparated: true,
        average: true,
      },
      fullWithTwoDecimals: {
        output: 'currency',
        mantissa: 2,
        spaceSeparated: true,
        thousandSeparated: true,
      },
      fullWithTwoDecimalsNoCurrency: {
        mantissa: 2,
        thousandSeparated: true,
      },
      fullWithNoDecimals: {
        output: 'currency',
        spaceSeparated: true,
        thousandSeparated: true,
        mantissa: 0,
      },
    },
  });

  numbro.setLanguage('ru-RU');
}
