import axios from 'axios';

export function setData(data) {
  return dispatch =>
    dispatch({
      type: 'SET_DATA',
      payload: data,
    });
}

export function userAuth(user) {
  const config = {
    headers: {
      Accept: 'application/json',
    },
  };

  const body = {
    ...user,
  };

  return () => axios.post('/api/v1/auth/login/', body, config);
}

export function validateToken(token) {
  const body = {
    token: token,
  };

  return dispatch =>
    axios
      .post('/api/v1/auth/verify/', body)
      .then(response => {
        dispatch({
          type: 'VERIFY_RESPONSE',
          payload: response.data,
        });
      })
      .catch(function(error) {
        console.log(error);
      });
}

export function setToken(data) {
  return dispatch =>
    dispatch({
      type: 'VERIFY_RESPONSE',
      payload: data,
    });
}

export function userLogout() {
  return dispatch =>
    axios
      .post('/api/v1/auth/logout/')
      .then(response => {
        dispatch({
          type: 'USER_LOGOUT',
          payload: [],
        });
      })
      .catch(function(error) {
        console.log(error);
      });
}
