import { formSummaryData } from '../../../util/tableHelpers';
import numbro from 'numbro';

const initialState = {
  initialData: [],
  tableData: [],
  columns: [],
  selectedUsers: [],
  dataFormat: 'month_days',
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'GET_INITIAL_DATA':
      let columns = formSummaryData(action.payload.entities, action.payload.format, action.date);
      let formedData = [];
      action.payload.entities.map(row => {
        let rowData = {};
        columns.map(column => {
          if (column.dataIndex !== 'users') {
            if (row.data.length) {
              let summary = 0;
              row.data.map(col => {
                summary = summary + col.earnings;
                rowData[col.date] = numbro(Math.round(col.earnings))
                  .format('0,0')
                  .replace(/,/g, ' ');
              });
              rowData['summary'] = numbro(Math.round(+summary))
                .format('0,0')
                .replace(/,/g, ' ');
            } else {
              rowData[column.dataIndex] = 0;
            }
          }
        });
        formedData.push({
          key: row.entity.id,
          users: row.entity.name,
          ...rowData,
        });
      });
      return {
        ...state,
        initialData: formedData,
        tableData: formedData,
        columns: columns,
      };

    case 'FILTER_CHANGE':
      let selectedValue = action.payload;
      let tempTableData = state.initialData.slice();
      let filteredData = [];
      if (selectedValue.length) {
        tempTableData.map(row => {
          selectedValue.map(key => {
            if (row.users === key) {
              filteredData.push(row);
            }
          });
        });
      } else {
        filteredData = tempTableData;
      }

      return {
        ...state,
        tableData: filteredData,
        selectedUsers: selectedValue,
      };

    case 'SET_CURRENT_MONTH':
      let tempColumns = formSummaryData(action.payload.entities, action.payload.format, action.date);
      let tempData = [];
      let initialData = [];
      let tempSelected = state.selectedUsers.slice();

      action.payload.entities.map(row => {
        let rowData = {};
        tempColumns.map(column => {
          if (column.dataIndex !== 'users') {
            if (row.data.length) {
              let summary = 0;
              if (action.payload.format === 'month_days') {
                row.data.map(col => {
                  summary = +summary + +col.earnings;
                  rowData[col.date] = numbro(Math.round(col.earnings))
                    .format('0,0')
                    .replace(/,/g, ' ');
                });
              } else if (action.payload.format === 'month_weeks') {
                row.data.map(col => {
                  summary = +summary + +col.earnings;
                  rowData[col.week] = numbro(Math.round(col.earnings))
                    .format('0,0')
                    .replace(/,/g, ' ');
                });
              } else if (action.payload.format === 'year_months') {
                row.data.map(col => {
                  summary = +summary + +col.earnings;
                  rowData[col.month] = numbro(Math.round(col.earnings))
                    .format('0,0')
                    .replace(/,/g, ' ');
                });
              }
              rowData['summary'] = numbro(Math.round(+summary))
                .format('0,0')
                .replace(/,/g, ' ');
            } else {
              rowData[column.dataIndex] = 0;
            }
          }
        });
        if (tempSelected.length) {
          tempSelected.map(key => {
            if (row.entity.name === key) {
              tempData.push({
                key: row.entity.id,
                users: row.entity.name,
                ...rowData,
              });
            }
          });
        } else {
          tempData.push({
            key: row.entity.id,
            users: row.entity.name,
            ...rowData,
          });
        }

        initialData.push({
          key: row.entity.id,
          users: row.entity.name,
          ...rowData,
        });
      });

      return {
        ...state,
        tableData: tempData,
        initialData: initialData,
        columns: tempColumns,
        dataFormat: action.dataFormat,
      };

    default:
      return {
        ...state,
      };
  }
};
