import React from 'react';
import { Modal, Button, message, Select } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import './modalOrderDelete.scss';

const Option = Select.Option;

class ModalOrderDelete extends React.Component {
  state = {
    provider: [],
    visible: false,
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = e => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = e => {
    this.setState({
      provider: [],
      visible: false,
    });
  };

  handleProviderChange = value => {
    this.setState({
      provider: value,
    });
  };

  handleProviderSubmit = () => {
    const { provider } = this.state;
    if (provider) {
      this.props.deleteOrder(provider);
      this.setState({
        provider: [],
        visible: false,
      });
      message.success('Поставщик успешно удален');
    } else {
      message.error('Выберите поставщика для удаления');
    }
  };

  render() {
    const { tableHeaders } = this.props;
    return (
      <div className="modal-order_add-container">
        <Button type="primary" onClick={this.showModal}>
          <FontAwesomeIcon icon={faTrashAlt} />
        </Button>
        <Modal
          title="Удалить поставщика"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
          className="modal-order_add"
        >
          <span className="label">Удаление поставщика</span>
          <Select
            placeholder="Выберите поставщика"
            onChange={this.handleProviderChange}
            size="large"
            value={this.state.provider}
          >
            {tableHeaders.map((order, index) => {
              return (
                <Option key={index} value={order.value}>
                  {order.value}
                </Option>
              );
            })}
          </Select>
          <div className="buttons-container">
            <Button onClick={this.handleCancel} type="danger" ghost>
              <FontAwesomeIcon icon={faTimes} />
              Отмена
            </Button>
            <Button onClick={this.handleProviderSubmit} className="button-submit">
              <FontAwesomeIcon icon={faTrashAlt} />
              Удалить
            </Button>
          </div>
        </Modal>
      </div>
    );
  }
}

export default ModalOrderDelete;
