import moment from 'moment';
import { findIndex } from 'lodash';

const currentMonth = moment()
  .startOf('month')
  .add(1, 'days');

const initialState = {
  pickedDate: currentMonth,
  sidebarVisible: false,
  entity: null,
  points: [],
  activePoint: {},
  dataChanged: false,
  loading: false,
  dataLoading: true,
  dataLoadingError: false,
  dataSaveError: false,
  mainTable: null,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'HANDLE_YEAR_CHANGE':
      return {
        ...state,
        pickedDate: action.payload,
      };

    case 'GET_TABLES_DATA':
      const { entity, points } = action.payload;
      return {
        ...state,
        entity: entity,
        points: points,
        mainTable: points[0].tables.point_data,
        expenseTable: points[0].tables.other_expenses,
        moneyTable: points[0].tables.movement_of_money,
        ordersTable: points[0].tables.order,
        activePoint: points[0],
        pickedDate: action.date ? action.date : state.pickedDate,
        dataLoadingError: false,
      };

    case 'CHANGE_ACTIVE_POINT':
      const newPointsData = action.points;
      let activePointIndex = findIndex(newPointsData, o => o.id === +action.payload);
      let activePoint = newPointsData[activePointIndex];

      return {
        ...state,
        points: newPointsData,
        activePoint: activePoint,
        mainTable: activePoint.tables.point_data,
        expenseTable: activePoint.tables.other_expenses,
        moneyTable: activePoint.tables.movement_of_money,
        ordersTable: activePoint.tables.order,
      };

    case 'DATA_CHANGED':
      return {
        ...state,
        dataChanged: true,
      };

    case 'DATA_SAVED':
      return {
        ...state,
        dataChanged: false,
        dataSaveError: false,
      };

    case 'DATA_LOADING':
      return {
        ...state,
        dataLoading: true,
      };

    case 'DATA_LOADED':
      return {
        ...state,
        dataLoading: false,
      };

    case 'DATA_LOADING_SUCCESS':
      return {
        ...state,
        dataLoadingError: false,
      };

    case 'DATA_LOADING_ERROR':
      return {
        ...state,
        dataLoadingError: true,
        dataLoading: false,
      };

    case 'DATA_SAVE_ERROR':
      return {
        ...state,
        dataSaveError: true,
      };

    case 'SIDEBAR_CHANGE':
      return {
        ...state,
        sidebarVisible: !state.sidebarVisible,
      };

    case 'USER_LOGOUT':
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
