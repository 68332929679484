import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, message } from 'antd';
import {
  initTable,
  updateTable,
  addNewOrder,
  deleteOrder,
  getPrevProviders,
  getPrevMonthData,
} from '../../modules/redux/actions/ordersTable';
import { findIndex, isEmpty, isNumber } from 'lodash';
import { HotTable } from 'react-handsontable/dist/ce/react-handsontable';
import Handsontable from 'handsontable';
import ModalOrderAdd from '../ModalOrderAdd/ModalOrderAdd';
import ModalOrderDelete from '../ModalOrderDelete/ModalOrderDelete';

import './tableOrders.scss';

class TableOrders extends Component {
  constructor(props) {
    super(props);

    this.textRenderer = this.textRenderer.bind(this);
    this.numRenderer = this.numRenderer.bind(this);
  }

  componentDidMount() {
    this.props.getPrevMonthData(this.props.pickedDate);
    this.props.initTable();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pickedDate !== this.props.pickedDate) {
      this.props.getPrevMonthData(this.props.pickedDate);
      this.props.initTable('date');
    }

    if (prevProps.activePoint.name !== this.props.activePoint.name) {
      this.props.getPrevMonthData(this.props.pickedDate);
      this.props.initTable();
    }
  }

  textRenderer(instance, td, row, col, prop, value, cellProperties) {
    Handsontable.renderers.TextRenderer.apply(this, arguments);
    const { prevMonthData, activePoint } = this.props;
    let columnName = cellProperties.prop.split('_');

    if (columnName[1] === 'Num') {
      if (prevMonthData && !isEmpty(prevMonthData)) {
        let activePointIndex = findIndex(prevMonthData.points, o => o.id === activePoint.id);
        let prevMonthActivePoint = prevMonthData.points[activePointIndex];
        if (
          prevMonthActivePoint &&
          prevMonthActivePoint.tables &&
          prevMonthActivePoint.tables.order &&
          prevMonthActivePoint.tables.order.rows
        ) {
          prevMonthActivePoint.tables.order.rows.map(rowTmp => {
            if (rowTmp.hasOwnProperty(cellProperties.prop)) {
              if (rowTmp[cellProperties.prop] === value) {
                instance.setCellMeta(row, col + 1, 'readOnly', true);
              }
            }
          });
        }
      }
    }
  }

  numRenderer(instance, td, row, col, prop, value, cellProperties) {
    let columnName = cellProperties.prop.split('_');

    cellProperties.numericFormat = {
      pattern: '$0,0.00',
      culture: 'ru-RU',
    };

    let data = instance.getSourceData();

    if (columnName[1] === 'Order') {
      if (value) {
        let numColValue = instance.getDataAtCell(row, col - 1);
        let nextColValue = instance.getDataAtCell(row, col + 1);
        let balance = instance.getDataAtCell(row, col + 2);
        let nextColIndex = col + 1;

        if (numColValue) {
          if (!nextColValue) {
            instance.setCellMeta(row, col, 'className', 'colorized-red htCenter');
            data.map((rowEl, i) => {
              if (i !== row && rowEl[columnName[0] + '_Num'] === data[row][columnName[0] + '_Num']) {
                if (rowEl[columnName[0] + '_Return'] - value >= 0) {
                  instance.setCellMeta(row, col, 'className', 'colorized-green htCenter');
                  instance.setCellMeta(i, nextColIndex, 'className', 'colorized-green htCenter');
                } else {
                  instance.setCellMeta(row, col, 'className', 'colorized-red htCenter');
                  instance.setCellMeta(i, nextColIndex, 'className', 'colorized-red htCenter');
                }
              }
            });
            if (balance <= 0) {
              instance.setCellMeta(row, col, 'className', 'colorized-green htCenter');
            }
          } else {
            if (nextColValue - value >= 0) {
              instance.setCellMeta(row, col, 'className', 'colorized-green htCenter');
            } else {
              instance.setCellMeta(row, col, 'className', 'colorized-red htCenter');
            }
          }
        }
      } else {
        instance.setCellMeta(row, col, 'className', 'htCenter');
      }
    }

    if (columnName[1] === 'Return') {
      if (value) {
        let numColValue = instance.getDataAtCell(row, col - 2);
        let prevColValue = instance.getDataAtCell(row, col - 1);
        let balance = instance.getDataAtCell(row, col + 1);
        let prevColIndex = col - 1;
        if (numColValue) {
          if (!prevColValue) {
            data.map((rowEl, i) => {
              if (i !== row && rowEl[columnName[0] + '_Num'] === data[row][columnName[0] + '_Num']) {
                if (value - rowEl[columnName[0] + '_Order'] >= 0) {
                  instance.setCellMeta(row, col, 'className', 'colorized-green htCenter');
                  instance.setCellMeta(i, prevColIndex, 'className', 'colorized-green htCenter');
                } else {
                  instance.setCellMeta(row, col, 'className', 'colorized-red htCenter');
                  instance.setCellMeta(i, prevColIndex, 'className', 'colorized-red htCenter');
                }
              }
            });
            if (balance <= 0) {
              instance.setCellMeta(row, col, 'className', 'colorized-green htCenter');
            }
          } else {
            if (value - prevColValue >= 0) {
              instance.setCellMeta(row, col, 'className', 'colorized-green htCenter');
            } else {
              instance.setCellMeta(row, col, 'className', 'colorized-red htCenter');
            }
          }
        }
      } else {
        instance.setCellMeta(row, col, 'className', 'htCenter');
      }
    }

    if (columnName[1] === 'Balance') {
      instance.setCellMeta(row, col, 'className', 'text-black htCenter');
      if (value !== null && value !== undefined && isNumber(value)) {
        if (value <= 0) {
          instance.setCellMeta(row, col, 'className', 'colorized-green text-black htCenter');
        } else if (value > 0) {
          instance.setCellMeta(row, col, 'className', 'colorized-red text-black htCenter');
        }
      }
    }

    Handsontable.renderers.NumericRenderer.apply(this, arguments);
  }

  handleProvidersLoad = async () => {
    await this.props.getPrevProviders(this.props.pickedDate);
    if (this.props.nestedHeaders.length) {
      message.success('Поставщики из прошлого месяца успешно скопированы');
    } else {
      message.error('Поставщиков в прошлом месяце не найдено или произошла ошибка при загрузке данных');
    }
  };

  render() {
    const { tableData, tableHeaders, rowHeaders, columns, nestedHeaders, activePoint } = this.props;
    return (
      <div className="table-orders">
        {tableHeaders.length && tableData.length ? (
          <div className="table-orders_container">
            <HotTable
              settings={{
                nestedHeaders: nestedHeaders,
                rowHeaders: rowHeaders,
                columns: columns,
                data: tableData,
                fillHandle: false,
                rowHeaderWidth: 140,
                stretchH: 'all',
                className: 'htCenter',
                cells: (row, column, prop) => {
                  if (prop.length) {
                    let cellProperties = {};
                    let columnName = prop.split('_');

                    if (columnName[1] === 'Num') {
                      cellProperties.renderer = this.textRenderer;
                    } else if (columnName[1] === 'Balance') {
                      cellProperties.type = 'numeric';
                      cellProperties.readOnly = true;
                      cellProperties.renderer = this.numRenderer;
                    } else {
                      cellProperties.type = 'numeric';
                      cellProperties.renderer = this.numRenderer;
                    }

                    return cellProperties;
                  }
                },
              }}
              maxRows={tableData.length}
              beforeChange={(changes, source) => {
                this.props.updateTable(changes, activePoint.id);
              }}
              renderer={this.cellRenderer}
            />
            <div className="table-orders_actions">
              <ModalOrderAdd addNewOrder={this.props.addNewOrder} float tableHeaders={tableHeaders} />
              <ModalOrderDelete deleteOrder={this.props.deleteOrder} tableHeaders={tableHeaders} />
            </div>
          </div>
        ) : (
          <div className="table-orders_empty">
            <ModalOrderAdd addNewOrder={this.props.addNewOrder} tableHeaders={tableHeaders} />
            <Button type="primary" onClick={this.handleProvidersLoad} className="button-copy-providers">
              Скопировать поставщиков из прошлого месяца
            </Button>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    pickedDate: state.common.pickedDate,
    activePoint: state.common.activePoint,
    tableData: state.ordersTable.tableData,
    tableHeaders: state.ordersTable.tableHeaders,
    rowHeaders: state.ordersTable.rowHeaders,
    columns: state.ordersTable.columns,
    nestedHeaders: state.ordersTable.nestedHeaders,
    prevMonthData: state.ordersTable.prevMonthData,
  };
}

export default connect(
  mapStateToProps,
  {
    initTable,
    updateTable,
    addNewOrder,
    deleteOrder,
    getPrevProviders,
    getPrevMonthData,
  }
)(TableOrders);
