import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import moment from 'moment';
import 'moment/locale/ru';
import Root from './routes/Root';
import configureStore from './store';

import 'bootstrap/dist/css/bootstrap-grid.min.css';
import 'handsontable/dist/handsontable.full.css';
import './App.scss';

const history = createBrowserHistory();
const store = configureStore();
moment.locale('ru');

export default class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Root />
        </ConnectedRouter>
      </Provider>
    );
  }
}
