import { createExpenseTableData, updateTotalExpenses } from '../../../util/tableHelpers';

const initialState = {
  tableData: [],
  rowHeaders: [],
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'INIT_EXPENSE_TABLE_DATA':
      let initTableData;
      if (action.payload.expenseTable && action.payload.expenseTable.length) {
        initTableData = action.payload.expenseTable.slice();
      } else {
        initTableData = createExpenseTableData(action.payload.pickedDate);
      }

      let totalRowsIndexes = [];
      initTableData.map((data, index) => {
        if (data.date === 'Итого за неделю') {
          totalRowsIndexes.push(index);
        }
      });

      const rowHeaders = initTableData.map(data => {
        return data.date;
      });

      return {
        ...state,
        rowHeaders: rowHeaders,
        tableData: initTableData,
        totalRowsIndexes: totalRowsIndexes,
      };

    case 'UPDATE_EXPENSE_TABLE_DATA':
      let row = action.payload[0][0];
      let column = action.payload[0][1];
      let newValue = action.payload[0][3];
      let tableData = state.tableData.slice();
      tableData[row][column] = newValue;

      if (row.date !== 'Итого за неделю' && row.date !== 'Итого за месяц') {
        tableData = updateTotalExpenses(state.totalRowsIndexes, tableData, row);
      }

      return {
        ...state,
        tableData: tableData,
      };

    case 'RESET_STATE':
      return initialState;

    default:
      return state;
  }
};
