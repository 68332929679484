import { createStore, applyMiddleware, compose } from 'redux';
import { createBrowserHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import rootReducer from './modules/redux/reducers/root';

const history = createBrowserHistory();

const middlewares = [thunk];
const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

export default function configureStore(initialState) {
  const enhancer = composeEnhancers(applyMiddleware(routerMiddleware(history), ...middlewares));
  const store = createStore(connectRouter(history)(rootReducer), initialState, enhancer);

  return store;
}
